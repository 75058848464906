import { useNavigate } from "react-router-dom";
import { Main, Heading, Button, Logo } from "../ui-kit/index.js";

export default function Page404() {
    const navigate = useNavigate();
        return (
            <Main
              textSize="base"  background="base-0" width={'stretch'}
              direction="flex-col" selfAlign="center" alignItems="center" justifyContent="center"
              marginX="md" marginY="sm"
              gap="base" corners="lg"
            >
                <Logo size="48px" type="symbol" />
                <Heading level={1} text="404: Page Not Found" />
                <Button text="Return" type="accent"  onClick={() => navigate('/')} />
            </Main>
            
    )
}