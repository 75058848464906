
import { Button, ButtonIcon, Main, } from '../ui-kit/index.js';
import Dictionary from "./Dictionary.js";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LoadingScreen from '../misc/LoadingScreen.js';
import { supabase } from '../supabaseClient.js';

export default function Document({view, editing=false, user}) {
    
    const navigate = useNavigate();
    const location = useLocation();
    const showDictionary = view?.showDictionary;
    const documentId = useParams().id;
    const [ document, setDocument ] = useState(null);
    const [ isEditing, setIsEditing ] = useState(editing);

    // Fetch the document
    useEffect(() => {
        async function getDocument() {
            let { data: documents, error } = await supabase
            .from('documents')
            .select('*')
            .eq('id', documentId)

            if (error) {    
                console.error('Failed to fetch document:', error);
            } else {
                const doc = documents[0]
                setDocument(doc);
                
                if (doc?.thumbnail) {
                    const thumbSrc = doc?.thumbnail
                    setThumbnailUrl(thumbSrc)
                }
            }
        }
        getDocument();
    }, [documentId]);

    // Changes page URL when state is switched from view to edit 
    useEffect(() => {
        // Change the URL without reloading the page
        if (isEditing) {
          if (!location.pathname.endsWith('/edit')) {
            navigate(`${location.pathname}/edit`, { replace: true });
          }
        } else {
          if (location.pathname.endsWith('/edit')) {
            navigate(location.pathname.replace('/edit', ''), { replace: true });
          }
        }
    }, [isEditing, navigate, location.pathname]);

    const is_favorite = document?.is_favorite;
    
    async function handleDocFave(id) {
        const oldDoc = document
        const newDoc = {...oldDoc, is_favorite: !oldDoc.is_favorite}
    
        if (newDoc) {
            try {
                const { data, error } = await supabase
                .from('documents')
                .update({ is_favorite: newDoc.is_favorite })
                .eq('id', newDoc.id)
                .select()
    
                if (error) {
                    throw new Error('Failed to update document');
                } else {
                    setDocument(newDoc)
                }

            } catch (error) {
                console.error('Failed to update document:', error);
        }
    }}

    const textBoxes = [
        {lang: 'Eng', title: document?.titleEng, text: document?.textEng},
        {lang: 'Mand', title: document?.titleMand, text: document?.textMand},
        {lang: 'Pinyin', title: document?.titlePinyin, text: document?.textPinyin}
    ]

    const defaultImage = "https://images.unsplash.com/photo-1466354424719-343280fe118b?crop=entropy&cs=srgb&fm=jpg&ixid=M3w0OTQ5NTB8MHwxfHNlYXJjaHwxfHxmaXNoJTIwamFwYW58ZW58MHx8fHwxNzIxODU5MDE0fDA&ixlib=rb-4.0.3&q=85"
    const [thumbnailUrl, setThumbnailUrl] = useState(null);

    const inputFileRef = useRef(null);
    const userId = user.id;

    async function handleFileChange (event) {
        const file = event.target.files[0];
        let thumbnailPath = null;
        if (file) {
            const fileName = `thumbnail_${document.id}`; 
            const path = `${userId}/${fileName}`;
            
            try {
                let { data, error } = await supabase.storage
                    .from('thumbnails') 
                    .upload(path, file, { upsert: true });

                thumbnailPath = data ? data.path : null;
                
            } catch (error) {
                console.error('Unexpected error during file upload:', error);
            }
            
            const fileData = thumbnailPath ? supabase.storage.from(`thumbnails`).getPublicUrl(thumbnailPath)?.data : null
            const publicUrl = fileData?.publicUrl || null;

            
            setDocument(prevDoc => ({
                ...prevDoc,
                thumbnail: publicUrl
            }));
            setThumbnailUrl(publicUrl);
        
        } 
    };

    const [isTranslating, setIsTranslating] = useState(false);
    
    function handleTranslate() {
        setIsTranslating(true);
        setTimeout(() => {
            setIsTranslating(false);
        }, 2000)
    }
    async function handleDelete(doc) {
        if (window.confirm('Are you sure you want to delete this document?')) {
                        
            const { data, error } = await supabase
                .from('documents')
                .delete()
                .eq('id', doc.id)
            
            if (error) {
                alert('Failed to delete document', error.message);
            } else {
                navigate('/documents')
            }
        }
    }

    async function handleDocSave() {
        
        try {
            const { data, error } = await supabase
            .from('documents')
            .update(document)
            .eq('id', document.id)
            .select()
            .single()
            

            if (error) {
                console.error('Failed to add new document:', error);
            }
            const record = data[0]
            if (record.thumbnail) {
                setThumbnailUrl(record.thumbnail)
                setIsEditing(false)   
                }
            }
        
        catch (error) {
            await cancelUpdate()
        }
    }

    async function cancelUpdate() {
        // Fetch the document againt
        const data = await supabase.from('documents').select('*').eq('id', documentId).single()
        const doc = data.data
        
        // Update local state & thumbnail
        setDocument(doc)
        setThumbnailUrl(doc?.thumbnail)
        setIsEditing(false)
    }

    const handleDocEdit = (key, value) => {
        setDocument({...document, [key]: value})
    }

    if (!document) return <LoadingScreen /> 
    
    else return (
        <>
        {/* Body */}
        <Main width="stretch" textSize="base" direction="flex-col" selfAlign="center" background="base-0" alignItems="start" justifyContent="start" gap="none" marginX="base" marginY="base" corners="lg">
        <div className='flex flex-col w-full bg-base-100 md:rounded-t-lg items-stretch justify-end flex-shrink-0 min-h-[240px] md:h-[320px]'
            style={{
                width: '100%',
                background: `linear-gradient(to top, rgba(0, 0, 0, 0.5) 40%, transparent 100%), url(${thumbnailUrl ? thumbnailUrl : defaultImage})  no-repeat center / cover`
            }}
        >
            {isEditing &&
        <div className='flex flex-row flex-grow pt-4 px-xl items-start justify-center gap-sm text-base-0'>
                    <input
                        type="file"
                        accept=".svg,.png,.jpg,.jpeg"
                        style={{ display: 'none' }}
                        ref={inputFileRef}
                        onChange={handleFileChange}
                    />
                    <Button type="secondary" leftIcon={'image'} size="small"
                        text={thumbnailUrl ? 'Change Cover' : 'Add Cover'}
                        onClick={() => inputFileRef.current.click()}
                    />
                    {thumbnailUrl && 
                        <ButtonIcon
                        type="secondary"
                        leftIcon={'image'}
                        icon='xmark'
                        size="small"
                        style={'filled'}
                        onClick={() => {setDocument({...document, thumbnail: null}); setThumbnailUrl(null)}}
                    />}
            </div>}
        <div className='flex flex-col md:flex-row w-full h-auto md:items-end justify-between gap-md py-sm md:py-md  px-lg md:px-xl rounded-t-3x mx-auto
         text-base-0 backdrop-blur-[1px]'>   
            <div className='flex flex-col items-baseline relative group '>
            <div className='flex flex-col md:flex-row md:gap-md items-baseline'>
            <h2 className='text-base font-semibold mb-sm md:mb-0'
            contentEditable={isEditing}
            suppressContentEditableWarning={true}
            onBlur={(e) => handleDocEdit('titleMand', e.target.innerText)}
            >
                {document?.titleMand}
            </h2>
            <h2 className={`hidden md:flex text-sm opacity-70 md:opacity-50 hover:opacity-70 font-normal transition-all duration-150
            ${isEditing && '!opacity-100'}`}
            contentEditable={isEditing}
            suppressContentEditableWarning={true}
            onBlur={(e) => handleDocEdit('titlePinyin', e.target.innerText)}

            >
                {document?.titlePinyin}
            </h2>
            </div>
            <h1 className='text-xl md:text-5xl font-semibold truncate drop-shadow-md leading-tight whitespace-normal'
            contentEditable={isEditing}
            suppressContentEditableWarning={true}
            onBlur={(e) => handleDocEdit('titleEng', e.target.innerText)}
            >
                {document?.titleEng}
            </h1>
            </div>

            {/* Button Set */}
            
            <div className='flex flex-row gap-md items-end mb-3'>
                {isEditing ? <>
               
        {/*<Select
            size={'small'}
            bgColor={'base-100'}
            hasOutline={false}
            value={document.level}
            onSelect={(value) => handleDocEdit('level', value)} 
            options={['beginner', 'medium', 'hard']}
                />
        
          <Button type="secondary" text="Translate" leftIcon="translate" size="small" style="filled" 
          isLoading={isTranslating} onClick={handleTranslate}
          />*/}
          <ButtonIcon  icon="trash"  type="warning"  text="Delete" size="small"  onClick={() => handleDelete(document)} />
          <Button type="secondary" text="Cancel" rightIcon="none" leftIcon="none" size="small" 
          onClick={cancelUpdate}
          />
          <Button type="success" text="Save" rightIcon="none" leftIcon="none" size="small" 
          onClick={handleDocSave}
          />
               
          </> :
                    <>
                <ButtonIcon type={is_favorite ? 'accent' : 'ghost'} alignSelf="center" icon="heart" 
                onClick={() => handleDocFave(document.id)} 
                
                size={'small'} 
                />
                <ButtonIcon type="ghost" alignSelf="center" icon="edit"  size={'small'}
                onClick={() => setIsEditing(true)}
                hideOnMobile={true}
                />
                
                {/*
                <Button type="secondary" text="Mark as Done" size={'small'}
                onClick={() => alert('Does not do anything yet')}
                />*/}
                </>
                }
            </div>
        </div>
        </div>
            <div className="flex flex-col md:flex-row w-full flex-grow h-auto items-stretch px-lg md:px-xl py-lg gap-2xl">
            {textBoxes.map((item, index) => (
                <div className="flex flex-col w-full h-full" key={index}>
                <p className={`whitespace-pre-wrap ${isEditing ? 'bg-base-50' : ''}`}
                contentEditable={isEditing}
                suppressContentEditableWarning={true}
                onChange={(e) => setDocument({...document, [`text${item.lang}`]: e.target.innerText})}
                >
                {item.text}
                </p>
                </div>
            ))}
            </div>
        </Main>

        {/* Sidebar */}
        <div className={`flex flex-col gap-md items-start justify-start transition-all duration-150 ${showDictionary ? `w-[320px] p-md` : `w-0 opacity-0`} h-full overflow-hidden mt-md`}>
            <Dictionary />
        </div>
        </>
    )
}
