import { useNavigate } from 'react-router-dom';
import { Header, FlexBox, Button, Logo, Main, Form, Heading, InputText, Link, Text, AppShell, Divider } from '../ui-kit/index.js';
import { useState } from 'react';
import { validateRegisterForm } from './validationFunctions.js';


export default function RegisterPage({setUser}) {

    const navigate = useNavigate();
    const [ formData, setFormData ] = useState({
        firstname: {value: '', error: '', type: 'text', label: 'First Name'},
        lastname: {value: '', error: '', type: 'text', label: 'Last Name'},
        email: {value: '', error: '', type: 'email', label: 'Email'},
        password: {value: '', error: '', type: 'password', label: 'Password'},
        confirmPassword: {value: '', error: '', type: 'password', label: 'Confirm Password'}
    })
    const [ isLoading, setIsLoading ] = useState(false);
    const [ error, setError ] = useState(null);

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        setError(null);
        const { isValid, errors } = validateRegisterForm(formData)

        if (!isValid) {
            setFormData(errors);
            setIsLoading(false);
            return; 
        }

        try {

            let { data, error } = await window.supabase.auth.signUp({
                email: formData.email.value,
                password: formData.password.value,
              })
            
            if (data) {
                const userId = data?.user?.id;
                // console.log("User ID:", userId);
                const profile = await window.supabase
                        .from('profiles')  
                        .insert([
                            {
                            id: userId, 
                            first_name: formData.firstname.value, 
                            last_name: formData.lastname.value}])
                        .select()
        
                const profileData = profile.data[0]
                setUser({...data.user, profile: profileData});
                navigate("/documents");
            } else {
              setError("Registration failed");
            }
          } catch (err) {
            console.error("Authentication error:", err);
            setError("Something went wring. Please try again.");
          } finally {
            setIsLoading(false);
          }
        
    }
    
    function handleGoogleAuth() {
        alert('Google Register');
        navigate('/documents');
    }

    function handleAppleAuth() {
        alert('Apple Register');
        navigate('/documents');
    }

    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            handleSubmit(event);
        }
      } 

    return (
        <Main
        textSize="base"  background="base-0" 
        direction="flex-col" selfAlign="center" alignItems="center" justifyContent="center"
        marginX="md" marginY="sm"
        gap="base" corners="lg"
      >
        <form 
            className="flex flex-col w-full h-auto gap-lg items-center justify-start max-w-[480px] rounded-xl p-xl"
            onSubmit={(e) => {e.preventDefault();}}
            onKeyDown={handleKeyDown}
            >
            
            {/* Form header */}
            <div className="flex flex-col w-full h-auto gap-base items-center justify-start">
                <Logo size="48px" type="symbol" />
                <Heading text="Learn Mandarin" textSize="2xl" marginTop="none" textAlign="center" />
                <div className="flex flex-row gap-sm items-center w-full justify-center">
                        <Text text="Have an account?" textSize="sm" />
                        <Link URL="/login" text="Sign In" textSize="sm" textColor="base-content" underline="always" />
                </div>
            </div>
            
            {/* Input group */}
            <div className="flex flex-col gap-sm items-stretch w-full justify-center">
                <div className='flex flex-row gap-sm items-start w-full'>
                {Object.keys(formData)
                .filter(key => key == 'firstname' || key == 'lastname')
                .map((key, index) => (
                    <InputText 
                        key={index} 
                        label={formData[key]?.label}
                        type={formData[key]?.type}
                        width={'full'}
                        placeholder={formData[key]?.label}
                        value={formData[key]?.value}
                        error={formData[key]?.error}
                        state={formData[key]?.error ? 'error' : 'default'}
                        helperText={formData[key]?.error}
                        onChange={(val) => setFormData(
                            {...formData, 
                                [key]: {
                                    ...formData[key],
                                    value: val, 
                                    error: '', 
                                }})}
                        
                        />
                ))}
                </div>
                
                {Object.keys(formData)
                .filter(key => key !== 'firstname' && key !== 'lastname')
                .map((key, index) => (
                    <InputText 
                        key={index} 
                        width={'full'}
                        label={formData[key]?.label}
                        type={formData[key]?.type}
                        placeholder={formData[key]?.label}
                        value={formData[key]?.value}
                        error={formData[key]?.error}
                        state={formData[key]?.error ? 'error' : 'default'}
                        helperText={formData[key]?.error}
                        onChange={(val) => setFormData(
                            {...formData, 
                                [key]: {
                                    ...formData[key],
                                    value: val, 
                                    error: '', 
                                }})}
                        
                        />
                ))}
            </div>

            {/* Buttons */}
            <div className="flex flex-col gap-base items-stretch w-full justify-center">
                <Button text="Create Account" type="accent" onClick={handleSubmit} isLoading={isLoading} />
                {/*
                <Button text="Sign Up with Google" type="ghost" leftIcon="google" onClick={handleGoogleAuth} 
                isDisabled={true} // isLoading
                />
                {/* Apple Sign In required developer account and setup            
                <Button text="Sign In with Apple" type="secondary" leftIcon="apple" onClick={handleAppleLogin} isDisabled={isLoading}/>*/}
            </div>
            </form>
        </Main>
    )
}