import { useLocation, useNavigate } from "react-router-dom";
import { Button, ButtonIcon, HeaderLink, Logo, UserMenu } from "../ui-kit/index.js";

export default function AppHeader({tabs, view, setView, user, setUser}) {

  const navigate = useNavigate();
  const location = useLocation();

  const isDocView = location.pathname.includes('/documents/') && !location.pathname.endsWith('/edit');
  const first_name = user?.profile?.first_name || 'User';

  async function handleLogout() {
    try {
        const { error } = await window.supabase.auth.signOut();
        setUser(null)
        navigate('/login'); 
    } catch (error) {
        console.error('Logout failed:', error);
    }
  }

    return (
      <div className="w-full flex flex-col items-center relative bg-base-0 border-b border-base-100 md:bg-transparent md:border-b-0 select-none
      z-10
      ">
          <div  className={`flex flex-row min-h-[48px] w-full border-box px-md md:px-xl py-xs w-full max-w-full justify-between items-center`}>
        <div className="flex flex-row h-auto items-center gap-md">
          <Logo size="28px" type="symbol" 
          listeners={{onClick: () => navigate('/')}}
          />
          <div className="flex flex-row h-auto items-center gap-xs z-0">
              {tabs?.map((tab, index) => (
                  <HeaderLink key={index} text={tab?.name} background={
                      location.pathname === tab.path ? 'base-100' : null} size="small" 
                      onClick={() => navigate(tab.path)}/>
              ))}
          </div>
      </div>
      <div className="flex flex-row items-center gap-base justify-end">
          {isDocView &&
          <ButtonIcon type={view?.showDictionary ? 'accent' : 'ghost'} icon="translate" 
          onClick={() => setView({...view, showDictionary: !view?.showDictionary})}
          size={'small'}
          hideOnMobile={true}
          />}
          <UserMenu name={first_name} color="accent" avatarPosition={'right'} >
            <div className="px-3 py-1 text-xs font-medium">{user?.email}</div>
            <Button type="ghost" text="Logout" size="small" onClick={handleLogout}/>
          </UserMenu>
      </div>
      </div> 
      </div> 
    )
}