export const getIconName = (preformattedName, library) => {
    const libraryName = iconMap[preformattedName]?.[library];
    
    const prefixMap = {
        feather: 'Fi',
        ionic: 'Io',
        fontawesome: 'Fa',
        material: 'MdOutline',
        heroicons: 'HiOutline',  
        iconoir: '',
    };

    const prefix = prefixMap[library];

    // Convert the base icon name to CamelCase with the correct prefix
    let reactIconsName = libraryName?.replace(/-([a-z])/g, (match, letter) => letter?.toUpperCase());
    reactIconsName = prefix + reactIconsName?.charAt(0).toUpperCase() + reactIconsName?.slice(1);
    
    return reactIconsName;
};

export const iconMap = {
    'add': {
        feather: 'plus',
        ionic: 'add', 
        material: 'add',
        heroicons: 'plus',
        iconoir: 'plus',
    }, 
    'plus': {
        feather: 'plus',
        ionic: 'add', 
        material: 'add',
        heroicons: 'plus',
        iconoir: 'plus',
    },
    'edit': {
        feather: 'edit',
        ionic: 'create-outline', 
        material: 'edit',
        heroicons: 'pencil',
        iconoir: 'edit',
    },
    'plus-circle': {
        feather: 'plus-circle',
        ionic: 'add-circle-outline', 
        material: 'add-circle-outline',
        heroicons: 'plus-circle',
        iconoir: 'plus-circle',
    },
    'close': {
        feather: 'x',
        ionic: 'close-outline', 
        material: 'close',
        heroicons: 'x',
        iconoir: 'xmark',
    },
    'xmark': {
        feather: 'x',
        ionic: 'close-outline', 
        material: 'close',
        heroicons: 'x',
        iconoir: 'xmark',
    },
    'x-mark': {
        feather: 'x',
        ionic: 'close-outline', 
        material: 'close',
        heroicons: 'x',
        iconoir: 'xmark',
    },
    'home': {
        feather: 'home',
        ionic: 'home-outline', 
        material: 'home',
        heroicons: 'home',
        iconoir: 'home-alt',
    },
    'chevron-left': {
        feather: 'chevron-left',
        ionic: 'chevron-back-outline', 
        material: 'chevron-left',
        heroicons: 'chevron-left',
        iconoir: 'nav-arrow-left',
    },
    'chevron-right': {
        feather: 'chevron-right',
        ionic: 'chevron-forward', 
        material: 'chevron-right',
        heroicons: 'chevron-right',
        iconoir: 'nav-arrow-right',
    },
    'chevron-up': {
        feather: 'chevron-up',
        ionic: 'chevron-up-outline', 
        material: 'keyboard-arrow-up',
        heroicons: 'chevron-up',
        iconoir: 'nav-arrow-up',
    },
    'chevron-down': {
        feather: 'chevron-down',
        ionic: 'chevron-down-outline', 
        material: 'keyboard-arrow-down',
        heroicons: 'chevron-down',
        iconoir: 'nav-arrow-down',
    },
    'send': {
        feather: 'send',
        ionic: 'send-outline', 
        material: 'send',
        heroicons: 'paper-airplane',
        iconoir: 'send',
    },
    'heart': {
        feather: 'heart',
        ionic: 'heart-outline', 
        material: 'favorite-border',
        heroicons: 'heart',
        iconoir: 'heart',
    },
    'copy': {
        feather: 'copy',
        ionic: 'copy-outline', 
        material: 'content-copy',
        heroicons: 'duplicate',
        iconoir: 'copy',
    },
    'check': {
        feather: 'check',
        ionic: 'checkmark-outline', 
        material: 'check',
        heroicons: 'check',
        iconoir: 'check',
    },
    'truck': {
        feather: 'truck',
        ionic: 'car-outline', 
        material: 'local-shipping',
        heroicons: 'truck',
        iconoir: 'truck',
    },
    'building': {
        feather: 'home',
        ionic: 'business-outline',
        material: 'business',
        heroicons: 'building-office',
        iconoir: 'building',
    },
    'chart-up': {
        feather: 'trending-up',
        ionic: 'trending-up-outline',
        material: 'trending-up',
        heroicons: 'arrow-trending-up',
        iconoir: 'graph-up',
    }, 
    'chart-down': {
        feather: 'trending-down',
        ionic: 'trending-down-outline',
        material: 'trending-down',
        heroicons: 'arrow-trending-down',
        iconoir: 'graph-down',
    },
    'warning': {
        feather: 'alert-triangle',
        ionic: 'warning-outline',
        material: 'warning',
        heroicons: 'exclamation',
        iconoir: 'warning-triangle',
    },
    'calendar': {
        feather: 'calendar',
        ionic: 'calendar-outline',
        material: 'event',
        heroicons: 'calendar',
        iconoir: 'calendar',
    }, 
    'box': {
        feather: 'box',
        ionic: 'cube-outline',
        material: 'inbox',
        heroicons: 'cube',
        iconoir: 'box',
    },
    'refresh': {
        feather: 'rotate-cw',
        ionic: 'refresh-outline',
        material: 'refresh',
        heroicons: 'refresh',
        iconoir: 'refresh',
    },
    'bell': {
        feather: 'bell',
        ionic: 'notifications-outline',
        material: 'notifications',
        heroicons: 'bell',
        iconoir: 'bell',
    },
    'dashboard': {
        feather: 'grid',
        ionic: 'grid-outline',
        material: 'dashboard',
        heroicons: 'view-grid',
        iconoir: 'stats-up-square',
    },
    'activity': {
        feather: 'activity',
        ionic: 'pulse-outline',
        material: 'timeline',
        heroicons: 'chart-pie',
        iconoir: 'activity',
    },
    'people': {
        feather: 'users',
        ionic: 'people-outline',
        material: 'people',
        heroicons: 'user-group',
        iconoir: 'group',
    },
    'log-out': {
        feather: 'log-out',
        ionic: 'log-out-outline',
        material: 'logout',
        heroicons: 'logout',
        iconoir: 'log-out',
    },
    'settings': {
        feather: 'settings',
        ionic: 'settings-outline',
        material: 'settings',
        heroicons: 'cog',
        iconoir: 'settings',
    },
    'help': {
        feather: 'help-circle',
        ionic: 'help-circle-outline',
        material: 'help',
        heroicons: 'question-mark-circle',
        iconoir: 'help-circle'
    },
    'support': {
        feather: 'life-buoy',
        ionic: 'help-buoy-outline',
        material: 'support',
        heroicons: 'support',
        iconoir: 'lifebelt',
    },
    'link': {
        feather: 'link',
        ionic: 'link-outline',
        material: 'link',
        heroicons: 'link',
        iconoir: 'link',
    }, 
    'google': {
        feather: 'google',
        ionic: 'logo-google',
        material: 'google',
        heroicons: 'logo-google',
        iconoir: 'google',
    },
    'apple': {
        feather: 'apple',
        ionic: 'logo-apple',
        material: 'apple',
        heroicons: 'logo-apple',
        iconoir: 'apple',
    },
    'facebook': {
        feather: 'facebook',
        ionic: 'logo-facebook',
        material: 'facebook',
        heroicons: 'logo-facebook',
        iconoir: 'facebook',
    },
    'twitter': {
        feather: 'twitter',
        ionic: 'logo-twitter',
        material: 'twitter',
        heroicons: 'logo-twitter',
        iconoir: 'twitter',
    },
    'instagram': {
        feather: 'instagram',
        ionic: 'logo-instagram',
        material: 'instagram',
        heroicons: 'logo-instagram',
        iconoir: 'instagram',
    },
    'linkedin': {
        feather: 'linkedin',
        ionic: 'logo-linkedin',
        material: 'linkedin',
        heroicons: 'logo-linkedin',
        iconoir: 'linkedin',
    },
    'youtube': {
        feather: 'youtube',
        ionic: 'logo-youtube',
        material: 'youtube',
        heroicons: 'logo-youtube',
        iconoir: 'youtube',
    },
    'github': {
        feather: 'github',
        ionic: 'logo-github',
        material: 'github',
        heroicons: 'logo-github',
        iconoir: 'github',
    },
    'microsoft': {
        feather: 'microsoft',
        ionic: 'logo-windows',
        material: 'microsoft',
        heroicons: 'logo-windows',
        iconoir: 'windows',
    },
    'folder': {
        feather: 'folder',
        ionic: 'folder-outline',
        material: 'folder',
        heroicons: 'folder',
        iconoir: 'folder',
    },
    'search': {
        feather: 'search',
        ionic: 'search-outline',
        material: 'search',
        heroicons: 'search',
        iconoir: 'search',
    },
    'globe': {
        feather: 'globe',
        ionic: 'globe-outline',
        material: 'public',
        heroicons: 'globe',
        iconoir: 'globe',
    },
    'language': {
        feather: 'globe',
        ionic: 'globe-outline',
        material: 'public',
        heroicons: 'globe',
        iconoir: 'language',
    },
    'message': {
        feather: 'message-square',
        ionic: 'chatbubble-ellipses-outline',
        material: 'chat',
        heroicons: 'chat-alt',
        iconoir: 'chat-bubble',
    },
    'more-horiz': {
        feather: 'more-horizontal',
        ionic: 'ellipsis-horizontal-outline',
        material: 'more-horiz',
        heroicons: 'dots-horizontal',
        iconoir: 'more-horiz',
    },
    'arrow-down': {
        feather: 'arrow-down',
        ionic: 'arrow-down-outline',
        material: 'arrow-down',
        heroicons: 'arrow-down',
        iconoir: 'arrow-down',
    },
    'arrow-up': {
        feather: 'arrow-up',
        ionic: 'arrow-up-outline',
        material: 'arrow-up',
        heroicons: 'arrow-up',
        iconoir: 'arrow-up',
    },
    'arrow-left': {
        feather: 'arrow-left',
        ionic: 'arrow-back-outline',
        material: 'arrow-left',
        heroicons: 'arrow-left',
        iconoir: 'arrow-left',
    },
    'arrow-right': {
        feather: 'arrow-right',
        ionic: 'arrow-forward-outline',
        material: 'arrow-right',
        heroicons: 'arrow-right',
        iconoir: 'arrow-right',
    },
    'hourglass': {
        feather: 'clock',
        ionic: 'time-outline',
        material: 'hourglass',
        heroicons: 'clock',
        iconoir: 'hourglass',
    },
    'clock': {
        feather: 'clock',
        ionic: 'time-outline',
        material: 'clock',
        heroicons: 'clock',
        iconoir: 'clock',
    },
    'favorite': {
        feather: 'heart',
        ionic: 'heart-outline',
        material: 'favorite-border',
        heroicons: 'heart',
        iconoir: 'heart',
    },
    'star': {
        feather: 'star',
        ionic: 'star-outline',
        material: 'star-border',
        heroicons: 'star',
        iconoir: 'star',
    },
    'image': {
        feather: 'image',
        ionic: 'image-outline',
        material: 'image',
        heroicons: 'image',
        iconoir: 'image',
    },
    'trash': {
        feather: 'trash',
        ionic: 'trash-outline',
        material: 'delete',
        heroicons: 'trash',
        iconoir: 'trash',
    },
    'translate': {
        feather: 'globe',
        ionic: 'language-outline',
        material: 'translate',
        heroicons: 'language',
        iconoir: 'translate',
    },
    'arrow-up-circle': {
        feather: 'arrow-up-circle',
        ionic: 'arrow-up-circle-outline',
        material: 'arrow-upward',   
        heroicons: 'arrow-up-circle',
        iconoir: 'arrow-up-circle-solid',
    },
    
}
