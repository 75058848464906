import PropTypes from 'prop-types'
import { useEffect, useState } from 'react';

export default function Checkbox(props) {
    
    const { type, label, isChecked, size, isPartial, onChange, attributes, listeners } = props
    
    const [checked, setChecked] = useState(isChecked); 
    useEffect(() => {
        setChecked(isChecked);
    }, [isChecked]);
    
    const onCheckboxChange = () => {
        if (onChange) {
            onChange();
        } else {
            setChecked(!checked)
        }
    }
    // this local state simply illustrates the checked state of the checkbox, 
    // in your app remove the local state and useEffect. 

    const sizeStyles = 
        size == 'small' ? `gap-xs text-xs` 
        : size == 'large' ? `gap-base text-base`
        : `gap-sm text-sm`;
        
    const fillColorMap = {
        'standard': 'bg-base-content border border-base-content',
        'primary': 'bg-primary border border-primary',
        'accent': 'bg-accent border border-accent',
        'warning': 'bg-warning-content border border-warning-content',
        'success': 'bg-success-content border border-success-content',
        'disabled': 'bg-base-200 border border-base-300'
    };
    
    const typeStyles = checked ? fillColorMap[type] : type =='disabled' ? 'base-200 border border-base-300' : 'base-100 border border-base-300'
    let wrapperClasses = `flex items-start ${sizeStyles} cursor-default`

    const checkboxDimensions = size == 'small' ? 'w-4 h-4 rounded mt-px' : size == 'large' ? 'w-6 h-6 rounded-lg' : 'w-5 h-5 rounded-md'
    const checkboxClasses = `${checkboxDimensions} flex items-center justify-center ${typeStyles} `

    

    const labelClasses = `${type == 'disabled' ? 'opacity-60' : ''}`
    const checkColor = 'base-0'
    const noLabel = !label || label == ''
    return (
        <div 
        {...attributes} {...listeners} 
            className={wrapperClasses} 
onClick={onCheckboxChange}
        >
            <div className="relative flex-shrink-0 inline-block">
                <span className={checkboxClasses}>
                    {checked ? !isPartial ? 
                        <svg className="w-5 h-5" viewBox="0 0 20 20" aria-hidden="true" fill={`var(--${checkColor})`}>
                            <path fillRule="evenodd" d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg> :
                        <svg className="w-5 h-5" viewBox="0 0 20 20" aria-hidden="true" fill={`var(--${checkColor})`}>
                            <rect x="4" y="9" width="12" height="2" />
                        </svg>
                        : null
                    }
                </span>
            </div>
            {!noLabel && <span className={labelClasses} style={{textWrap: 'balance'}}>
{label}
            </span>}
        </div>
    );
}
Checkbox.propTypes = {
    type: PropTypes.oneOf(['standard', 'primary', 'accent', 'warning', 'disabled', 'success' ]),
    label: PropTypes.string,
    isChecked: PropTypes.bool,
    isPartial: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    onChange: PropTypes.func
    
};

Checkbox.defaultProps = {
    type: 'standard',
    label: 'checkbox',
    isChecked: false,
    isPartial: false,
    size: 'medium',
    onChange: null

};

