import { useEffect, useRef, useState } from 'react';
import { Modal, Button, FlexBox, TextArea, InputText, ButtonIcon, Select } from '../ui-kit/index.js';
import { supabase } from '../supabaseClient.js';

 const documentTemplate = {
    titleEng: "Untitled",
    titleMand: '无标题',
    titlePinyin: "wú biāo tí",
    textEng: '',
    textMand: '',
    textPinyin: '',
    thumbnail: null, 
    status: 'to_process',
    level: '',
    is_favorite: false
  }

export default function AddDocument({
    onClose, onSave, user
}) {
    
    const inputFileRef = useRef(null);
    const [ newDoc, setNewDoc ] = useState(documentTemplate);
    const [isTranslating, setIsTranslating] = useState(false);
    
    function handleTranslate() {
        
        setIsTranslating(true);
        const url = 'https://obscure-brushlands-74422-4128828a689c.herokuapp.com/';
    
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({}),  // Assuming you need to send an empty object; adjust as necessary
        })
        .then(response => {
            // Check if the response is ok (status in the range 200-299)
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            console.log('Response:', response);
            return response.json();
        })
        .then(data => {
            console.log('Success:', data);
             /*
            const { titleMand, titlePinyin, textMand, textPinyin } = data
            setNewDoc(prevDoc => ({
                ...prevDoc,
                titleMand,
                titlePinyin,
                textMand,
                textPinyin
            }));

            period!
            
            */
        })
        .catch(error => {
            console.error('Error:', error);
        })
        .finally(() => {
            setTimeout(() => {
                setIsTranslating(false);
            }, 1000);
        });
    }

    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    const handleFileChange = event => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.type;
            const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml'];
            if (validImageTypes.includes(fileType)) {
                const fileUrl = URL.createObjectURL(file); // Create a URL for the file
                setThumbnailUrl(fileUrl); // Update the thumbnail URL state
                setNewDoc(prevDoc => ({
                    ...prevDoc,
                    thumbnail: file // Keep the file in state but don't trigger URL creation every render
                }));
            } else {
                alert('Only JPG, PNG, and SVG files are allowed.');
            }
        }
    };

    
    useEffect(() => {
        // Clean up the created URL to avoid memory leaks
        return () => {
            if (thumbnailUrl) {
                URL.revokeObjectURL(thumbnailUrl);
            }
        };
    }, [thumbnailUrl]); 


    const handleDocEdit = (key, value) => {
        setNewDoc({...newDoc, [key]: value})
    }

    async function handleDocSave() {
        // Validate input
        let errors = {...noErrors};
        for (let key in noErrors) {
            if (!newDoc[key]) {
                errors[key] = `${key} is required`;  // Set the error message if the field is empty
            }
        }
        const isErrorPresent = Object.values(errors).some(error => error !== '');
    
        if (isErrorPresent) { 
            setErrorMessages(errors);
            return; 
        }
    
        const userId = user.id;
        try {
            // If there's a thumbnail, upload it to Supabase Storage
            let thumbnailPath = null;
            if (newDoc.thumbnail) {
                const file = newDoc.thumbnail;
                const fileName = `${Date.now()}_${file.name}`; // Create a unique file name to avoid overwriting
                const path = `${userId}/${fileName}`;
            
                try {
                    // Attempt to upload the file
                    let { data, error } = await supabase.storage
                        .from('thumbnails') // Assuming 'thumbnails' is your bucket name
                        .upload(path, file);
    
                    thumbnailPath = data ? data.path : null;
                    
                } catch (error) {
                    console.error('Unexpected error during file upload:', error);
                }
            }
            const fileData = thumbnailPath ? supabase.storage.from(`thumbnails`).getPublicUrl(thumbnailPath)?.data : null
            const publicUrl = fileData?.publicUrl || null;

            // Replace the thumbnail with the path or URL
            const docToSave = { 
                ...newDoc, 
                thumbnail: publicUrl,
            };

            // Save the document with the thumbnail URL (if available)
            onSave(docToSave); // Pass the document data back to the parent component
            onClose(); // Close the modal
        } catch (error) {
            console.error('Error saving document:', error);
            // Handle any additional error handling or UI updates here
        }
    }

    const textBoxes = [
        {lang: "English", value: "Eng", localValue: 'English', color: 'base-0'},
        {lang: "Mandarin", value: "Mand", localValue: '普通话', color: 'base-50'},
        {lang: "Pinyin", value: "Pinyin", localValue: '拼音', color: 'base-100'},
    ]

    const noErrors = {
        titleEng: '',
        titleMand: '',
        titlePinyin: '',
        textEng: '',
        textMand: '',
        textPinyin: ''
    }
    
    const [errorMessages, setErrorMessages] = useState(noErrors)

    return (
        <Modal modalBackground="base-0" backdrop="dark" width="1200px" paddingX="lg" paddingY="xl" corners="base" gap="lg" onClose={onClose} >
            <div className={`flex flex-col justify-between py-md px-base items-end bg-base-100 min-h-[280px] rounded-lg`}
            style={{
                background: thumbnailUrl ? `linear-gradient(to top, rgba(0, 0, 0, 0.5) 30%, transparent 100%), url(${thumbnailUrl})  no-repeat center / cover` : 'linear-gradient(to top, var(--base-500) 0%, var(--base-400) 100%)', 
            }}
            >
             
            <div className='flex flex-row mx-auto mt-4 gap-sm text-base-0'>
                    <input
                        type="file"
                        accept=".svg,.png,.jpg,.jpeg"
                        style={{ display: 'none' }}
                        ref={inputFileRef}
                        onChange={handleFileChange}
                    />
                    <Button
                        type="secondary"
                        leftIcon={'image'}
                        text={newDoc?.thumbnail ? 'Change Cover' : 'Add Cover'}
                        size="small"
                        style={newDoc?.thumbnail ? 'filled' : "outlined"}
                        onClick={() => inputFileRef.current.click()}
                    />
                    {newDoc?.thumbnail && 
                        <ButtonIcon
                        type="secondary"
                        leftIcon={'image'}
                        icon='xmark'
                        size="small"
                        style={'filled'}
                        onClick={() => {setNewDoc({...newDoc, thumbnail: null}); setThumbnailUrl(null)}}
                    />}
            </div>


      
    <div className='flex flex-row justify-between items-end w-full'>
      <input 
      
      className='text-5xl text-base-0 w-full flex-grow  font-semibold drop-shadow-md leading-tight whitespace-normal appearance-none bg-transparent border-none focus:border-none focus:outline-none'
        value={newDoc[`titleEng`]}
        onChange={(e) => handleDocEdit(`titleEng`, e.target.value)}
        />
        
        
        <div className='flex flex-row items-center justify-end gap-sm'>       
        <Select 
            size={'small'}
            bgColor={'base-100'}
            hasOutline={false}
            value={newDoc.level}
            onSelect={(value) => handleDocEdit('level', value)} 
            options={['beginner', 'medium', 'hard']}
        />
        
          <Button type="secondary" text="Translate" leftIcon="translate" size="small" style="filled" 
          isLoading={isTranslating} onClick={handleTranslate}
          />
          <Button type="secondary" text="Save" rightIcon="none" leftIcon="none" size="small" 
          onClick={handleDocSave}
          />
          </div>
        
      </div>
    </div>
    <div className='w-full flex flex-row'>
    {/* textboxes */}
    {textBoxes.map(textbox => 
        <div className={`flex flex-col px-sm py-xs gap-xs w-1/3 rounded-md 
        bg-${textbox.color}`} key={textbox.lang}>
        
            
        <div className='flex flex-row items-center justify-between w-full gap-sm'>
            <InputText 
                size="small" bgColor={null} hasOutline={false}
                width={'full'}
                placeholder={`${textbox?.lang} text`}
                value={newDoc[`title${textbox?.value}`]}
                state={errorMessages[`title${textbox?.value}`] ? 'error' : 'default'}
                helperText={errorMessages[`title${textbox?.value}`]}
                onChange={(value) => handleDocEdit(`title${textbox?.value}`, value)}
            />
            <span className='text-xs opacity-50 select-none flex-shrink-0'>{textbox?.localValue}</span>
        </div>
        <TextArea 
            value={newDoc[`text${textbox?.value}`]}
            placeholder={`Enter ${textbox?.lang} text here...`}
            onChange={(value) => handleDocEdit(`text${textbox?.value}`, value)}
            state={errorMessages[`title${textbox?.value}`] ? 'error' : 'default'}
            helperText={errorMessages[`title${textbox?.value}`]}
            size="medium"  maxRows={2} defaultRows={12} width="full" label="" bgColor="none" hasCharacterCount={false} hasOutline={false} 
            />
        </div>
    )}
    
    </div>
    
  </Modal>
    )
}
