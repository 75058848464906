import { useNavigate } from "react-router-dom";
import { Button, Header, Logo } from "../ui-kit/index.js";

export default function AuthHeader() {
    const navigate = useNavigate();
    
    return (
      <div className="w-full flex flex-col items-center relative bg-base-0 border-b border-base-100 md:bg-transparent md:border-b-0">
        <div  className={`flex flex-row min-h-[48px] w-full border-box px-md md:px-xl py-xs w-full max-w-full justify-between items-center`}
        >
          <div className="flex flex-row h-full items-center gap-lg">
            <Logo size="20px" type="logo" 
            listeners={{onClick: () => navigate('/')}}
            />
          </div>
          <div className="flex flex-row h-auto items-center gap-lg justify-end">
            {/*<Button type="secondary" text="Help" size="small" 
            onClick={() => alert('Help interaction')}
            />*/}
          </div>
        </div>
      </div>
    )
}