import { useState } from "react";
import { Main, Form, Heading, FlexBox, Text, Link, InputText, Button, Header, Logo, AppShell, Alert, FeaturePanel } from "../ui-kit/index.js";
import { useNavigate } from "react-router-dom";
import { validateLoginForm } from "./validationFunctions.js";

export default function LoginPage({setUser}) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: { value: "", error: "", type: "email", label: "Email" },
    password: { value: "", error: "", type: "password", label: "Password" },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    setError(null);
    const { isValid, errors } = validateLoginForm(formData);

    if (!isValid) {
      setFormData(errors);
      setIsLoading(false);
      return; 
    }
    try {
      // Attempting authentication
      const { data, error } = await window.supabase.auth.signInWithPassword({
        email: formData.email.value,
        password: formData.password.value,
      });
      

      if (error) {
        setError("Invalid email or password. Please try again.");
      } else if (data) {
        const userId = data?.user?.id;
        const profile = await window.supabase
              .from('profiles')  
              .select('*')  
              .eq('id', userId)
              .single();


        setUser({...data.user, profile: profile.data});
        navigate("/documents");
      }
  } catch (err) {
      console.error("Authentication error:", err);
      setError("Something went wrong. Please try again.");
  } finally {
      setIsLoading(false);
  }
}

  async function handleGoogleLogin() { 
    alert("Google auth requires Google Cloud account and setup");
    // await window.pb.collection('users').authWithOAuth2({ provider: 'google' });
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
        handleSubmit(event);
    }
  } 

  return (
    <>
      <Main
        textSize="base"  background="base-0" 
        direction="flex-col" selfAlign="center" alignItems="center" justifyContent="center"
        gap="base" 
      >
        <form 
            className="flex flex-col w-full h-auto gap-lg items-center justify-start max-w-[480px] rounded-xl p-xl
            backdrop-blur-lg"
            style={{backgroundColor: "rgba(255, 255, 255, 0.88)"}}
            onSubmit={(e) => {e.preventDefault();}}
            onKeyDown={handleKeyDown}
            >
          
          {/* Form Header */}
          <div className="flex flex-col w-full h-auto gap-base items-center justify-start">
            <Logo size="48px" type="symbol" />
            <Heading text="Learn Mandarin" textSize="2xl" marginTop="none" textAlign="center"/>
            <div className="flex flex-row gap-sm items-center w-full justify-center">
                <Text text="New here?" textSize="sm" />
                <Link URL="/register" text="Create an account" textSize="sm" underline="onlyOnHover" />
            </div>
          </div>

          {/* Input Group */}
          <div className="flex flex-col gap-sm items-stretch w-full justify-center">
            {Object.keys(formData).map((key, index) => (
              <InputText
                key={index}
                width={"full"}
                label={formData[key]?.label}
                type={formData[key]?.type}
                placeholder={formData[key]?.label}
                value={formData[key]?.value}
                error={formData[key]?.error}
                state={formData[key]?.error ? "error" : "default"}
                helperText={formData[key]?.error}
                onChange={(val) =>
                  setFormData({
                    ...formData,
                    [key]: {
                      ...formData[key],
                      value: val,
                      error: "",
                    },
                  })
                }
              />
            ))}

            <Link URL="/forgot-password" text="Forgot password?" textSize="sm" underline="always"/>
            {error && <Alert type="error" text={error} />}
          </div>

          {/* Buttons */}
          <div className="flex flex-col gap-base items-stretch w-full justify-center">
            <Button text="Sign In" type="accent" onClick={handleSubmit} isLoading={isLoading} />
            {/*
            <Button text="Sign In with Google" type="ghost" leftIcon="google" 
            onClick={handleGoogleLogin} 
            isDisabled={true} // isLoading
              />

            {/* Apple Sign In required developer account and setup            
            <Button text="Sign In with Apple" type="secondary" leftIcon="apple" onClick={handleAppleLogin} isDisabled={isLoading}/>*/}
          </div>
        </form>
      </Main>
      </>
  );
}
