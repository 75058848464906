import React, { useState } from 'react';
import { FlexBox, Heading, InputText, Text, Button, ButtonIcon } from '../ui-kit/index.js';
import axios from 'axios';

export default function Dictionary() {
    const [searchTerm, setSearchTerm] = useState('');
    const [translation, setTranslation] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSearchChange = (event) => {
        
    };

    const handleTranslate = async () => {
        if (!searchTerm) return;
        setIsLoading(true);
        const result = await translateText(searchTerm, 'en'); // Assume target language is English
        setTranslation(result);
        setIsLoading(false);
    };

    const translateText = async (text, sourceLang, targetLang) => {
        const endpoint = "https://translation.googleapis.com/language/translate/v2";
        try {
          const response = await axios.post(endpoint, {
            q: text,
            source: sourceLang,
            target: targetLang,
            format: "text"
          }, {
            headers: {
              'Authorization': `Bearer YOUR_API_KEY`, // Replace 'YOUR_API_KEY' with your actual Google Cloud API key
              'Content-Type': 'application/json'
            }
          });
          return response.data.data.translations[0].translatedText;
        } catch (error) {
          console.error("Translation error:", error);
          return "Translation failed";
        }
      };


    return (
        <>
            <Heading text="Dictionary" textSize="xl" marginBottom="none" marginTop="none" />
            <div className='flex flex-col gap-base'>
                <div className='w-full flex-row gap-sm'>
                <InputText 
                value={searchTerm} 
                onChange={(value) => setSearchTerm(value)} 
                size="medium" width='auto' placeholder="Enter text" 
                leftIcon="translate" 
                //rightIcon={'arrow-up-circle'}
                />
                
                </div>
                </div>
                <div className='flex flex-col gap-sm'>
                    {isLoading ? <Text text="Loading..." /> : null}
                    <Text text={translation || "No translation available"} />
                    </div>
            
        </>
    );
}