export const columnData = [
  
  {
    accessor: "is_favorite",
    header: "",
    width: "4%",
    type: "object",
    direction: "flex-row",
    alignItems: "center",
    justifyContent: "start",
    hideOnMobile: true,
    component: "ButtonIcon",
    
  },
  /*
  {
    accessor: "status",
    header: "",
    width: "15%",
    type: "object",
    direction: "flex-row",
    alignItems: "center",
    justifyContent: "start",
    hideOnMobile: false,
    component: "Status",
  },*/
  {
    accessor: "titleEng",
    header: "Title",
    width: "24%",
    type: "text",
    direction: "flex-row",
    alignItems: "center",
    justifyContent: "start",
    isSortable: true,
    hideOnMobile: false,
  },
  {
    accessor: "titleMand",
    header: "中文",
    width: "16%",
    type: "text",
    direction: "flex-row",
    alignItems: "center",
    justifyContent: "start",
    isSortable: true,
    hideOnMobile: true,
  },
  /*
  {
    accessor: "titlePinyin",
    header: "wén dàng",
    width: "20%",
    type: "text",
    direction: "flex-row",
    alignItems: "center",
    justifyContent: "start",
    isSortable: true,
    hideOnMobile: false,
  },*/
  {
    accessor: "level",
    header: "Level",
    width: "12%",
    type: "object",
    direction: "flex-row",
    alignItems: "center",
    justifyContent: "start",
    hideOnMobile: false,
    component: "Badge",
  },
  {
    accessor: "createdDate",
    header: "Added",
    width: "12%",
    type: "text",
    direction: "flex-row",
    alignItems: "center",
    justifyContent: "start",
    isSortable: true,
    hideOnMobile: true,
  },
  {
    accessor: "actions",
    header: "",
    width: "8%",
    type: "arrayOfObjects",
    direction: "flex-row",
    alignItems: "center",
    justifyContent: "end",
    hideOnMobile: false,
    isSortable: false,
  },
];


