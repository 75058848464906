import { Main, Heading, Button, TableWidget, Loader } from '../ui-kit/index.js';

export default function LoadingScreen() {
    return (
  <Main width="stretch" textSize="base" direction="flex-col" selfAlign="center" background="base-0" paddingY="lg"  alignItems="center" justifyContent="center" 
          
          gap="lg" marginX="base" marginY="sm" paddingX="lg" corners="md">
            <Loader size='large' />
          </Main>
    )
  }