import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { LoginPage, RegisterPage, ForgotPassword } from './auth';
import { Document, Documents } from './documents';
import { AuthHeader, AppHeader } from './nav';
import { LoadingScreen, Page404 } from './misc';

import './App.css';


function AppShell({user, tabs, view, setView, setUser, children}) {
    const location = useLocation();
    const authPages = ['/login', '/register', '/forgot-password'];
    const isAuthPage = authPages.includes(location.pathname) || location.pathname === "*";
    return (
      <div className="flex flex-col w-screen items-center h-dvh flex-grow bg-base-50 text-base-content overflow-scroll"
      
      >
        <div className={` flex flex-col h-full items-stretch flex-grow w-full transitiona-all duration-150`}
        style={{maxWidth: isAuthPage ? '960px' : '1320px',
        }}
        
        >
        {(isAuthPage || !user) ? 
          <AuthHeader /> : 
          <AppHeader tabs={tabs} view={view} setView={setView} user={user} setUser={setUser}/> }
        <div className={`flex flex-row flex-grow w-full h-auto pb-xs`} >
          {children}
        </div>
        </div>
      </div>
    );
  }

function App() {
  
  const tabs = [
    { name: 'Texts', path: '/documents' },
    // { name: 'Flashcards', path: '/flashcards' },
    // ... add other tabs here
  ]
  
  const [view, setView] = useState({
    showDictionary: false,
  });

  const [auth, setAuth] = useState(false);
  const [user, setUser] = useState(null);
  
  const checkAuth = async () => {
    try {
      // Supabase automatically refreshes tokens if needed
      const {
        data: { session },
        error,
      } = await window.supabase.auth.getSession();
    
      // Check if the session is still valid
      if (session) {
        const profile = await window.supabase
              .from('profiles')  
              .select('*')  
              .eq('id', session.user.id)
              .single();

        setUser({...session.user, profile: profile.data});
        
      } else {
        setUser(null);
      }
    } catch (error) {
      setUser(null);
    }
    setAuth(true); // Auth check has completed
  };
  
  useEffect(() => {
    checkAuth();
  }, []);

  
  
  if (!auth) {
    return (
      <div className="flex flex-col w-screen items-center h-screen flex-grow bg-base-50 text-base-content overflow-scroll">
        <LoadingScreen />
      </div>
    )
  }

  return (
      <Router>
          <AppShell user={user} tabs={tabs} view={view} setView={setView} setUser={setUser}>
            <Routes>
              
              {/* Home */}
              <Route path="/" element={user ? <Navigate to="/documents" replac /> : <Navigate to="/login" replace />} />

              {/* Auth */}
              <Route path="/login" element={<LoginPage setUser={setUser}/>} />
              <Route path="/register" element={<RegisterPage setUser={setUser}/>} />
              <Route path="/forgot-password" element={<ForgotPassword />} />

              {/* Landing Page */}
              <Route path="/landing" element={<Navigate to="/" replace />} />
              <Route path="/static" element={<Navigate to="/" replace />} />

              {/* 404 Not Found */}
              <Route path="*" element={<Page404 />} />

              {/* App - Protected Routes */}
              <Route path="/documents" element={user ? 
                <Documents 
                  tabs={tabs} view={view} setView={setView} user={user}
                /> : 
                <Navigate to="/login" replace />} 
              />
              <Route path="/documents/:id" element={user ? <Document view={view} setView={setView} user={user}/> : <Navigate to="/login" replace />} />
              <Route path="/documents/:id/edit" element={user ? <Document view={view} setView={setView} editing={true} user={user}/> : <Navigate to="/login" replace />} />

            </Routes>
        </AppShell>
      </Router>
    
  );
}


export default App;