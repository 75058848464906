import { useState, useEffect } from 'react';
import { Main, Heading, Button, TableWidget, Loader } from '../ui-kit/index.js';
import { columnData } from './docTableCols.js';
import { useNavigate } from 'react-router-dom';
import AddDocument from './AddDocument.js';
import LoadingScreen from '../misc/LoadingScreen.js';
import { supabase } from '../supabaseClient.js';

export default function Documents({
    tabs, view, setView, user
}) {

  const [ docs, setDocs ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);
  
  
  useEffect(() => {
    let isActive = true;  // Flag to check component mount status

    async function getDocs() {
        const userEmail = user?.email

        try {
          
            const response = await supabase
              .from('documents')  
              .select('*')  
              .filter('email', 'eq', userEmail)
              .order('created_at', { ascending: false })
              .limit(50);

            
            const { data, error } = response;
              const userDocs = data || [];
            
            if (isActive) {
                setDocs(userDocs);
            }
        } catch (error) {
            console.error('Failed to fetch documents:', error);
        } finally {
            if (isActive) {
                setIsLoading(false);  // Stop loading when the operation is complete
            }
        }
    }

    getDocs();

    return () => {
        isActive = false;  // Set flag to false when component unmounts
    };
  }, []);
  
  async function handleDocFave(id) {
    
    const oldDoc = docs.find(doc => doc.id === id);
    const newDoc = {...oldDoc, is_favorite: !oldDoc.is_favorite}

    if (newDoc) {
        try {
            const { data, error } = await supabase
            .from('documents')
            .update({ is_favorite: newDoc.is_favorite })
            .eq('id', newDoc.id)
            .select()

            if (error) {
                throw new Error('Failed to update document');
            } else {
                const updatedDocs = docs.map(doc => doc.id === id ? newDoc : doc);
                setDocs(updatedDocs);
            }

            

                    
        } catch (error) {
            console.error('Failed to update document:', error);
            // Optionally, handle reverting the UI change if the update fails
            setDocs(docs); // Revert to original docs state if the update fails
        }
    }
}


async function addDocument(newDoc) {
  try {
      // Insert the new document into the 'documents' table
      let insertDoc = {...newDoc, email: user.email}
      
      const { data, error } = await supabase
          .from('documents')
          .insert([insertDoc]) // Insert the newDoc object into the table
          .select(); // Select the inserted data (optional)

      if (error) {
          throw new Error('Failed to add new document');
      } else {
          // console.log('Document added:', data);

          // Optionally update the local state with the newly added document
          setDocs(prevDocs => [...prevDocs, data[0]]);
      }
  } catch (error) {
      console.error('Error adding document:', error);
      // Handle any UI updates or notifications for the error case here
  }
}

    function handleDocEdit(id) {
      
        navigate(`/documents/${id}/edit`);
    }

    function handleDocOpen(id) {
        navigate(`/documents/${id}`);
    }

    const rowData = makeTableData({
      docs, 
      handleDocFave, 
      handleDocEdit, 
      handleDocOpen
    })

    const isEmpty = docs?.length === 0
    const navigate = useNavigate();
    return (
      
        <>
        {view.modal == 'add_document' && 
        <AddDocument 
        user={user}
        onSave={(doc) => addDocument(doc)} onClose={() => setView({...view, modal: null})}/>}
        {isLoading ? <LoadingScreen /> : 
        <Main width="stretch" textSize="base" direction="flex-col" selfAlign="center" background="base-0" paddingY="lg" alignItems="start" justifyContent="start" gap="lg" marginX="base" marginY="sm" paddingX="lg" corners="md">
              <div className="flex flex-col flex-nowrap  px-none py-base w-full max-w-full   self-auto text-base-content  gap-md  items-start justify-start h-auto" >
                <div className="flex flex-row flex-nowrap  px-none py-none w-full max-w-full   self-auto text-inherit  gap-md  items-end justify-between h-auto  ">
                    <Heading text="My Texts" textSize="xl" marginBottom="none" marginTop="none" />
                    {!isEmpty && <Button type="primary" text="New" rightIcon='add' size="small"  
                    hideOnMobile={true}
                    onClick={() => setView({...view, modal: 'add_document'})}
                    />}
                </div>
              {isEmpty ? <EmptyState 
              onAdd={() => setView({...view, modal: 'add_document'})}
              /> :
              <TableWidget    
                textSize="base" cellPaddingX="sm" cellPaddingY="sm" corners="md" bgColor="none" 
                columnData={columnData} 
                rowData={rowData}
                onRowClick={(recordId) => handleDocOpen(recordId)}
              />}
              </div>
        </Main>}
      </>
    
    )
}

function EmptyState({
  onAdd
}) {
return (
<div className="flex flex-col flex-nowrap  px-base py-base w-full max-w-full  bg-base-50 self-auto text-base-content  bg-base-50  gap-base  items-center justify-center h-auto  min-h-[280px]" 
  style={{minHeight: 280}}>
  <h1 className="text-ellipsis text-4xl text-base-700  font-medium text-center " style={{whiteSpace: 'pre-wrap'}}>
  目前没有文字
  </h1>
  <h1 className="text-ellipsis text-base text-base-500  font-medium text-center " style={{whiteSpace: 'pre-wrap'}}>
  Mùqián méiyǒu wénzì
  </h1>
  <Button type="accent" text="Add Document" size="large" marginTop="base" rightIcon={'add'}
  onClick={onAdd}
  />
</div>)
}


function makeTableData({docs, handleDocFave, handleDocEdit, handleDocOpen}) {
    let rows = []
    
    rows = docs.map((doc) => {
        const newRow = {}
        newRow.id = doc.id
        newRow.titleEng = doc.titleEng
        newRow.titleMand = doc.titleMand
        newRow.titlePinyin = doc.titlePinyin
        newRow.createdDate = formatDate(doc.created_at)
        newRow.status = {
          component: "Status",
          props: { text: doc.status, 
            color: doc.status == 'completed' ? 'success' : doc.status == 'in progress' ? 'info' : 'base-content',
            size: 'small' }
        }
        newRow.level = {
          component: "Badge",
          props: { text: doc.level, 
            color: doc.level == 'beginner' ? 'success-content' : doc.level == 'hard' ? 'accent' : 'info-content',
            type: 'light',
            isPill: true,
            size: 'small' }
        }
        
        newRow.is_favorite = {
            component: "ButtonIcon",
            props: { icon: "heart", type: doc?.is_favorite ? "primary" : "ghost" , size: "small", 
            onClick: (e) => {e.stopPropagation(); handleDocFave(doc.id)} },
        } 
        newRow.actions = [
          {
              component: "Button",
              props: { text: "Edit", type: "ghost", size: "small", 
              hideOnMobile: true,
              onClick: (e) => {e.stopPropagation(); handleDocEdit(doc.id)} },
          },
          {
            component: "Button",
            props: { text: "Study", type: "secondary", size: "small", onClick: (e) => {e.stopPropagation(); handleDocOpen(doc.id)} },
          },
        ]

        
        return newRow
    })

    return rows
    
}

function formatDate(dateString) {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const date = new Date(dateString);
  return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
}