import { useState } from "react";
import { Main, Heading, Link, InputText, Button, Logo, Alert } from "../ui-kit/index.js";
import { useNavigate } from "react-router-dom";
import { validateForgotPasswordForm, validateLoginForm } from "./validationFunctions.js";

export default function ForgotPassword() {
  
  const [formData, setFormData] = useState({
    email: { value: "", error: "", type: "email", label: "Email" },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccess(null);  // Resetting success message on new submission

    const { isValid, errors } = validateForgotPasswordForm(formData);

    if (!isValid) {
      setFormData(errors);
      setIsLoading(false);
      return;
    }
    
    try {
      const email = formData.email.value;
        let { data, error } = await window.supabase.auth.resetPasswordForEmail(email);
      console.log("Data:", data, "Error:", error);
      setSuccess('If an account with that email exists, we have sent a password reset email.');
      setFormData({ ...formData, email: { ...formData.email, value: '' } }); // Optionally clear the input field
    } catch (err) {
      console.error("Reset password error:", err);
      setError("Failed to send reset email. Please try again.");
    } finally {
      setIsLoading(false);
    }
}

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
        handleSubmit(event);
    }
  } 

  return (
      <Main
        width="960" textSize="base"  background="base-0" 
        direction="flex-col" selfAlign="center" alignItems="center" justifyContent="center"
        marginX="md" marginY="sm"
        gap="base" corners="lg"
        
        
      >
        <form 
            className="flex flex-col w-full h-auto gap-lg items-center justify-start max-w-[480px] rounded-xl p-xl"
            onSubmit={(e) => {e.preventDefault();}}
            onKeyDown={handleKeyDown}
            >
          
          {/* Form Header */}
          <div className="flex flex-col w-full h-auto gap-base items-center justify-start">
            <Logo size="48px" type="symbol" />
            <Heading text={success ? 'Check Inbox' : 'Forgot Password'} textSize="2xl" marginTop="none" textAlign="center"/>
          </div>

          {/* Input Group */}
          
          <div className="flex flex-col gap-sm items-stretch w-full justify-center">
          {!success && Object.keys(formData).map((key, index) => (
              <InputText
                key={index}
                width={"full"}
                label={formData[key]?.label}
                type={formData[key]?.type}
                placeholder={formData[key]?.label}
                value={formData[key]?.value}
                error={formData[key]?.error}
                state={formData[key]?.error ? "error" : "default"}
                helperText={formData[key]?.error}
                onChange={(val) =>
                  setFormData({
                    ...formData,
                    [key]: {
                      ...formData[key],
                      value: val,
                      error: "",
                    },
                  })
                }
              />
            ))}

            
            {error && <Alert type="error" text={error} />}
            {success && <Alert type="success" text={success} />}
          </div>

          {/* Buttons */}
          {!success ? 
          <div className="flex flex-col gap-base items-stretch w-full justify-center">
            <Button text="Email Reset Link" type="accent" onClick={handleSubmit} isLoading={isLoading} />
            <Link URL="/login" text="Back to Log In" textSize="sm" underline="always"/>
          </div> : 
          <div className="flex flex-col gap-base items-stretch w-full justify-center">
            <Button text="Try again" type="secondary" onClick={() => setSuccess(null)} />
          </div>}
        </form>
      </Main>
  );
}
