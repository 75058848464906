export function validateForgotPasswordForm(data) {
    let isValid = true;
    let errors = {...data}; 

    if (!data.email.value) {
        errors.email.error = 'Email is required';
        isValid = false;
    } else if (!data.email.value.includes('@') || !data.email.value.includes('.')) {
        errors.email.error = 'Invalid email';
        isValid = false;
    }

    return { isValid, errors };
}

export function validateLoginForm(data) {
    let isValid = true;
    let errors = {...data}; 

    if (!data.email.value) {
        errors.email.error = 'Email is required';
        isValid = false;
    } else if (!data.email.value.includes('@') || !data.email.value.includes('.')) {
        errors.email.error = 'Invalid email';
        isValid = false;
    }

    if (!data.password.value) {
        errors.password.error = 'Password is required';
        isValid = false;
    } else if (data.password.value.length < 8) {
        errors.password.error = 'Password must be at least 8 characters long';
        isValid = false;
    }

    return { isValid, errors };
}


export function validateRegisterForm(data) {
    let isValid = true;
    const errors = { ...data };

    // Validate first name
    if (!data.firstname.value) {
        errors.firstname.error = 'First name is required';
        isValid = false;
    }

    // Validate last name
    if (!data.lastname.value) {
        errors.lastname.error = 'Last name is required';
        isValid = false;
    }

    // Validate email
    if (!data.email.value) {
        errors.email.error = 'Email is required';
        isValid = false;
    } else if (!data.email.value.includes('@')) {
        errors.email.error = 'Invalid email';
        isValid = false;
    }

    // Validate password
    if (!data.password.value) {
        errors.password.error = 'Password is required';
        isValid = false;
    } else if (data.password.value.length < 8) {
        errors.password.error = 'Password must be at least 8 characters long';
        isValid = false;
    }

    // Validate confirm password
    if (data.password.value !== data.confirmPassword.value) {
        errors.confirmPassword.error = 'Passwords do not match';
        isValid = false;
    }

    return { isValid, errors };
}